import React, { Component } from 'react';
import SmallBoard from './SmallBoard';
import Logic from '../GameLogic';
import X from './X';
import O from './O';
import Cat from './Cat';

export default class Board extends Component {
    state = {
        enabled: [
            true, true, true, 
            true, true, true, 
            true, true, true, 
        ],
        winners: [
            'none', 'none', 'none', 
            'none', 'none', 'none', 
            'none', 'none', 'none', 
        ]
    }

    componentDidMount = () => {
        this.renderSmallBoards();
    }

    renderSmallBoards = () => {
        let arr = [];
        for (let i = 0; i < 9; i++) {
            arr.push(<SmallBoard id={i} key={'smallboard' + i} turn={this.props.turn} toggleTurn={this.props.toggleTurn} setActiveSmallBoard={this.setActiveSmallBoard} enabled={this.state.enabled[i]} winner={this.state.winners[i]} setSmallBoardWinner={this.setSmallBoardWinner}/>);    
        }
        return arr;
    }

    setActiveSmallBoard = (smallBoard, winners) => {
        winners = winners ? winners : this.state.winners;
        let enabled = [...this.state.enabled];
        if (winners[smallBoard] !== 'none') {
            console.log('that board is taken')
            for (let i = 0; i < enabled.length; i++) {
                if (winners[i] !== 'none') {
                    enabled[i] = false
                }else {
                    enabled[i] = true
                }
            }
        }else {
            for (let i = 0; i < enabled.length; i++) {
                if (i !== smallBoard) {
                    enabled[i] = false
                } else {
                    enabled[i] = true
                }
            }
        }
        this.setState({
            enabled
        })
    }

    setSmallBoardWinner = (box, index, winner) => {
        let winners = [...this.state.winners];
        winners[index] = winner;
        this.setState({
            winners
        }, this.setActiveSmallBoard(box, winners))
        const overallWinner = Logic.checkBoard(winners);
        if (overallWinner !== 'none') {
            this.props.setWinner(overallWinner);
        }
    }

    render() {
        if (this.props.winner === 'cat') {
            return (
                <div className="board">
                    <Cat id="board-cat"/>
                </div>
            )
        }else if (this.props.winner === 0) {
            return (
                <div className="board">
                    <O id="board-o" />
                </div>
            )
        }else if (this.props.winner === 1) {
            return (
                <div className="board">
                    <X id="board-x" />
                </div>
            )
        }else {
            return (
                <div className="board">
                    {this.renderSmallBoards()}
                </div>
            )
        }
    }
}