import React, { Component } from 'react';
import Box from './Box';
import Logic from '../GameLogic';
import X from './X';
import O from './O';
import Cat from './Cat';

export default class SmallBoard extends Component {
    state = {
        markers: [
            'none', 'none', 'none',
            'none', 'none', 'none',
            'none', 'none', 'none',
        ]
    }

    componentDidMount = () => {
        this.renderBoxes();
    }

    renderBoxes = () => {
        let arr = [];

        for (let i = 0; i < 9; i++) {
            arr.push(<Box id={`${this.props.id}${i}`} key={'box' + i} marker={this.state.markers[i]} setMarker={this.setMarker} enabled={this.props.enabled}/>)
        }

        return arr;
    }

    setMarker = id => {
        const index = parseInt(id.split('')[1]);
        const smallBoard = parseInt(id.split('')[0]);
        let markers = [...this.state.markers];
        markers[index] = this.props.turn;
        this.setState({
            markers
        })
        this.props.toggleTurn();
        const winner = Logic.checkSmallBoard(markers);
        if (winner !== 'none') {
            this.props.setSmallBoardWinner(index, smallBoard, winner);
        }else {
            this.props.setActiveSmallBoard(index)
        }
    }

    render() {
        if (this.props.winner === 'none') {
            return (
                <div className="small-board" style={{ backgroundColor: this.props.enabled ? '#75b09c' : '#5d525a' }} id={this.props.id}>
                    {this.renderBoxes()}
                </div>
            )
        } else if (this.props.winner === 0) {
            return (
                <div className="small-board" style={{ backgroundColor: '#5d525a' }} id={this.props.id}>
                    <O id="small-board-o"/>
                </div>
            )
        } else if (this.props.winner === 1) {
            return (
                <div className="small-board" style={{ backgroundColor: '#5d525a' }} id={this.props.id}>
                    <X id="small-board-x" />
                </div>
            )
        } else if (this.props.winner === 'cat') {
            return (
                <div className="small-board" style={{ backgroundColor: '#5d525a' }} id={this.props.id}>
                    <Cat id="small-board-cat" />
                </div>
            )
        }else {
            return (
                <div className="small-board" style={{ backgroundColor: '#5d525a' }} id={this.props.id}>
                    {this.renderBoxes()}
                </div>
            )
        }
    }
}