import React, { Component } from 'react';
import Game from './Game';

import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons'
import { faCat } from '@fortawesome/free-solid-svg-icons'

library.add(faTimes)
library.add(faSmileBeam)
library.add(faCat)

class App extends Component{
    render(){
        return(
            <Game />
        );
    } 
}

export default App;