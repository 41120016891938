import React, { Component } from 'react';
import O from './O';
import X from './X';

export default class Turn extends Component {

    render() {
        if (this.props.turn === 0) {
            return (
                <div className="turn">
                    <O id="turn-o"/>
                    <p style={{ color: '#f6ae2d'}}>It is O's turn</p>
                </div>
            )
        } else if (this.props.turn === 1) {
            return (
                <div className="turn">
                    <X id="turn-x"/>
                    <p style={{ color: '#f24333' }}>It is X's turn</p>
                </div>
            )
        }else {
            return (
                <div className="turn">
                    <p style={{color: 'white'}}>It isn't anyone's turn!</p>
                    <button onClick={this.props.reset}>Play again?</button>
                </div>
            )
        }
    }
}