class GameLogic {

    static checkSmallBoard = boxes => {
        const hori = this.checkHorizontalRows(boxes);
        const vert = this.checkVerticalRows(boxes);
        const diag = this.checkDiagonals(boxes);

        if (hori === 'none' && vert === 'none' && diag === 'none') {
            return this.checkForCat(boxes);    
        } else if (hori !== 'none') {
            return hori;
        } else if (vert !== 'none') {
            return vert;
        } else if (diag !== 'none') {
            return diag;
        }
    }

    static checkHorizontalRows = boxes => {
        if (boxes[0] + boxes[1] + boxes[2] === 3) {
            return 1
        } else if (boxes[0] + boxes[1] + boxes[2] === 0) {
            return 0
        } else if (boxes[3] + boxes[4] + boxes[5] === 3) {
            return 1
        } else if (boxes[3] + boxes[4] + boxes[5] === 0) {
            return 0
        } else if (boxes[6] + boxes[7] + boxes[8] === 3) {
            return 1
        } else if (boxes[6] + boxes[7] + boxes[8] === 0) {
            return 0
        } else {
            return 'none'
        }
    }

    static checkVerticalRows = boxes => {
        if (boxes[0] + boxes[3] + boxes[6] === 3) {
            return 1
        } else if (boxes[0] + boxes[3] + boxes[6] === 0) {
            return 0
        } else if (boxes[1] + boxes[4] + boxes[7] === 3) {
            return 1
        } else if (boxes[1] + boxes[4] + boxes[7] === 0) {
            return 0
        } else if (boxes[2] + boxes[5] + boxes[8] === 3) {
            return 1
        } else if (boxes[2] + boxes[5] + boxes[8] === 0) {
            return 0
        } else {
            return 'none'
        }
    }

    static checkDiagonals = boxes => {
        if (boxes[0] + boxes[4] + boxes[8] === 3) {
            return 1
        } else if (boxes[2] + boxes[4] + boxes[6] === 3) {
            return 1
        } else if (boxes[0] + boxes[4] + boxes[8] === 0) {
            return 0
        } else if (boxes[2] + boxes[4] + boxes[6] === 0) {
            return 0
        } else {
            return 'none';
        }
    }

    static checkForCat = boxes => {
        let takenPlaces = 0;
        for (let i = 0; i < boxes.length; i++) {
            if (boxes[i] !== 'none') {
                takenPlaces ++;
            }
        }

        if (takenPlaces === 9) {
            return 'cat';
        }else {
            return 'none'
        }
    }

    static checkBoard = boards => {
        const hori = this.checkHorizontalRows(boards);
        const vert = this.checkVerticalRows(boards);
        const diag = this.checkDiagonals(boards);

        if (hori === 'none' && vert === 'none' && diag === 'none') {
            return this.checkForCat(boards);
        } else if (hori !== 'none') {
            return hori;
        } else if (vert !== 'none') {
            return vert;
        } else if (diag !== 'none') {
            return diag;
        }
    }
}

export default GameLogic;