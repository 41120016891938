import React, {Component} from 'react';
import Board from './Components/Board';
import Turn from './Components/Turn';

export default class Game extends Component {
    state={
        turn: 0,
        winner: 'none'
    }
    
    reset = () => {
        window.location.reload();
    }

    setWinner = winner => {
        this.setState({
            winner,
            turn: 'none'
        })
    }

    toggleTurn = () => {
        if (this.state.turn === 0) {
            this.setState({
                turn: 1
            })
        }else {
            this.setState({
                turn: 0
            })
        }
    }

    render () {
        const {turn, winner} = this.state;
        return (
            <div className="game">
                <Turn turn={turn} reset={this.reset}/>
                <Board turn={turn} toggleTurn={this.toggleTurn} setWinner={this.setWinner} winner={winner}/>
            </div>
        )
    }
}