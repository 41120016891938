import React, { Component } from 'react';
import X from './X';
import O from './O';
import Cat from './Cat';

export default class Box extends Component {

    setMarker = e => {
        const position = e.target.id;
        this.props.setMarker(position);
    }

    render() {
        if (this.props.marker === 0) {
            return (
                <div className="box" id={this.props.id}>
                    <O id="box-o"/>
                </div>
            )
        }else if (this.props.marker === 1) {
            return (
                <div className="box" id={this.props.id}>
                    <X id="box-x"/>
                </div>
            )
        }else if (this.props.marker === 'cat') {
            return (
                <div className="box" id={this.props.id}>
                    <Cat id="box-cat" />
                </div>
            )
        } else {
            if (this.props.enabled) {
                return (
                    <div className="box hover" onClick={this.setMarker} id={this.props.id} style={{}}>
                    </div>
                )
            }else {
                return (
                    <div className="box" id={this.props.id} style={{}}>
                    </div>
                )
            }
        }
    }
}